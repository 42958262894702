import drills from './index.json';
import _ from "lodash"
import { useState } from 'react';

function App() {
  const [primary, setPrimary] = useState()
  const [video, setVideo] = useState()
  const [search, setSearch] = useState("")
  return (
    <>
     {video && 
        <div style={{position: 'fixed', width: "100vw", height: '100vh', zIndex: 1, background: "black"}}>
          <button className="btn btn-danger" style={{position: 'absolute', right: "2rem", top: "2rem", zIndex: 2}} onClick={() => setVideo(null)}>Kapat</button>
          <video style={{width: "100%", height:"100%"}} controls>
            <source src={video} type="video/mp4"></source>
          </video>
        </div>
      }
    <div className="container" style={{position:'relative'}}>
      <div className='row'>
      <div className='col col-md-12 col-xs-12 p-2'>
          <div class="form-group">
            <label for="exampleFormControlSelect1">Primary Kas Grubu</label>
            <select class="form-control" onChange={e => setPrimary(e.target.value)}>
              <option value={""}>Kas Grubu Seç</option>
              {_.uniq(drills.map(d => d.primary).flat()).map(d => 
                <option value={d}>{d}</option>
              )}
            </select>
          </div>
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Antrenman Ara" value={search} onChange={(e) => setSearch(e.target.value)}/>
            <div class="input-group-append" onClick={() => setSearch("")}>
              <span class="input-group-text">🔍</span>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        {drills.map((d) => 
         ((primary ? d.primary.includes(primary) : true) && 
          (d.title.toLowerCase().includes(search.toLowerCase()) || d.primary.join(" ").toLowerCase().includes(search.toLowerCase()))
         ) &&
          <div className='col col-md-3 col-xs-12 p-2 h-100'>
          <div className='card' key={d.title}>
            <div className="card-body">
              <button onClick={() => setVideo(d.video)} className="btn btn-link p-0 text-left"><h4>{d.title}</h4></button>
              <br/>
              <sub>Secondary</sub>
              <br/>
              {d.secondary.map(s => <button class="card-text btn btn-sm btn-primary mr-2 mt-2">{s || "Unset"}</button>)}
            </div>
          </div>
          </div>
        )}
      </div>
    </div>
    </>
  );
}

export default App;
